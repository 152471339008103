<template>
  <v-container class="about ps-6">
    <AvvC
      :print="true"
      :user="user"
    >
    </AvvC>
  </v-container>
</template>
<script>


import {mapGetters} from "vuex";

const AvvC = () => import("@/components/avvC.vue");

export default {
  name: 'AVV',
  components:{
    AvvC
  },
  mounted() {
    window.scrollTo(0, 0)

  },
  computed: {
    ...mapGetters('auth', {
      user: 'user',
    })
  },
}
</script>
<style>


</style>
